
import { mixins, Options } from "vue-class-component";
import DeclineService from "@/api/services/DeclineService"
import ButtonsWrap from "@/components/Button/ButtonsWrap.vue";
import CommonPage from "@/mixins/CommonPage";

@Options({
  components: {
    ButtonsWrap,
  },
})
export default class DeclineV2 extends mixins(CommonPage) {

  async onConfirm() {
    await DeclineService.changeById(this.id)
    this.$router.push({
      name: "declineConfirm",
      query: {
        id: this.id
      },
    });
  }

  onCancel(): void {
    this.$router.push({
      name: "declineCancel",
      query: {
        id: this.id
      },
    });
  }
}
